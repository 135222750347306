import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import LbsNavbar from "@components/navbar"
import Footer from "@components/footer"
import Announcement from "@components/announcement"

const CaseStudy = () => {
  return (
    <Layout>
      <SEO title="Ermeo Case Study" />
      <div className="page-layout">
        <section className="intro intro--no-waves">
          <Announcement />
          <div className="container">
            <LbsNavbar />
            <div className="container-blog container text-left">
              <h1 className="labs-title">
                Case study - Ermeo
                <span className="labs-blue">.</span>
              </h1>
            </div>
          </div>
        </section>
        <div className="intro-waves"></div>
        <section className="wave-wrapper">
          <section className="dark-bg page-wrapper">
            <div className="container-blog-content container">
              <div className="dark-bg page-container">
                <div>
                  <div className="row team-page">
                    <div className="members-list col-xl-3 col-lg-4 col-md-4">
                      <h3>Tech Stack:</h3>
                      <span className="badge badge-light"> Angular</span> <br />
                      <span className="badge badge-light">Ionic/Cordova</span>
                      <br />
                      <span className="badge badge-light"> Node.js</span> <br />
                      <span className="badge badge-light">Elastic Search</span>
                      <br />
                      <span className="badge badge-light"> SQLite</span> <br />
                      <span className="badge badge-light"> RabbitMQ</span>
                      <br />
                    </div>
                    <div className="member-details col-xl-9 col-lg-8 col-md-8 ">
                      <section className="pt-6 pt-md-0 case-study-page">
                        <p className="pt-2">
                          <strong>Case:</strong> How we helped Ermeo to build an
                          offline-first cross platform mobile application.
                        </p>
                        <p className="pt-2">
                          <strong>Industry:</strong> SAAS
                        </p>
                        <p className="pt-2">
                          <strong>Platform:</strong> Android, IOS, Desktop, Web
                        </p>
                        <p className="pt-4">
                          <strong>Intro</strong>
                          <br />
                          Ermeo offers a SaaS solution that helps plan, manage,
                          optimize industrial equipment maintenance
                          operations.The product is aimed to replace the use of
                          paper during maintenance rounds and operations on site
                          with an end-to-end digitized process.
                        </p>
                        <p className="pt-4">
                          <strong>Challenge</strong>
                          <br />
                          Having to maintain thousands of physical assets on
                          field by paper and legacy tools for documenting
                          interventions is a tedious, annoying and highly
                          impractical process for revenue-generating businesses.
                          Furthermore, not having unified, end-to-end assets
                          overview and maintenance schedules, guarantees to
                          cause delay in fixing incidents and confusion in case
                          of unavoidable delays.
                        </p>
                        <p className="pt-4">
                          <strong>Solution</strong>
                          <br />
                          In their ambition to create a offline-first mobile
                          application for assets and intervention management,
                          ERMEO partnered with Labs42. Labs42 assembled a
                          dedicated team with an Agile setup. Using their
                          expertise, Labs42 implemented a cross-platform
                          solution that is used on iOS, Android and Windows
                          devices. Having to provide offline features, Labs42
                          conducted the analysis phase to implement the offline
                          sync algorithm and ensured its optimal implementation.
                          At the same time Labs42 provided various automated
                          end-to-end tests to simulate real-life scenarios as
                          close as possible and ensure high quality and
                          stability of the delivered solution.
                        </p>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="dark-bg"></section>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export default CaseStudy
